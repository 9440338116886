<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传音频文件</span>
          </div>
          <div class="text item">
            <uploader
              class="uploader-example"
              :options="options"
              :auto-start="true"
              @file-added="onFileAdded"
              @file-success="onFileSuccess"
              @file-progress="onFileProgress"
              @file-error="onFileError"
            >
              <uploader-unsupport />
              <uploader-drop>
                <p>拖动音频文件到此处或</p>
                <uploader-btn :attrs="attrs">选择音频文件</uploader-btn>
              </uploader-drop>
              <uploader-list />
            </uploader>
          </div>
        </el-card>
      </el-col>
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>稿件信息</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onSubmit">发布</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="form.title" style="padding-right: 1px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.description" type="textarea" autosize style="padding-right: 1px;" />
              </el-form-item>
              <el-form-item label="可见范围">
                <el-select v-model="form.scope" placeholder="选择稿件的可见范围">
                  <el-option label="本人可见" value="1" />
                  <el-option label="所有人可见" value="2" />
                  <el-option label="VIP 可见" value="3" />
                  <el-option label="验证码可见" value="4" />
                </el-select>
              </el-form-item>
              <el-form-item label="定时发布">
                <el-date-picker
                  v-model="form.scheduledPubDate"
                  type="datetime"
                  placeholder="选择定时发布的时间"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { getServerInfo } from '@/api/content'
import { addAudioPost } from '@/api/audio'

export default {
  name: 'PublishAudio',
  data() {
    return {
      // ****************************************************************************************************************
      options: null,
      attrs: {
        accept: 'audio/*'
      },
      // ****************************************************************************************************************
      form: {
        channelId: process.env.VUE_APP_UPLOAD_AUDIO_CHANNEL,
        audioFileId: null,
        audioUrl: null,
        title: null,
        description: null,
        scope: '1',
        scheduledPubDate: null
      }
    }
  },
  created() {
    getServerInfo(this.form.channelId).then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.options = {
          target: resData.ossUrl,
          chunkSize: resData.maxSize,
          fileParameterName: 'file',
          testChunks: false,
          query: (file, chunk) => {
            return {
              channelId: this.form.channelId
            }
          },
          headers: {
            Authorization: 'Bearer ' + resData.token
          },
          withCredentials: false
        }
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '提示',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
  },
  methods: {
    // ****************************************************************************************************************
    onFileAdded(file) {
      this.setTitle(file.file.name)
    },
    onFileProgress(rootFile, file, chunk) {
    },
    onFileSuccess(rootFile, file, response, chunk) {
      const res = JSON.parse(response)
      if (res.code === 0) {
        const resData = res.data
        this.form.audioFileId = resData.uploadId
        this.form.audioUrl = resData.url
        this.$notify({
          title: '提示',
          message: '音频已上传',
          type: 'warning',
          duration: 3000
        })
      }
    },
    onFileError(rootFile, file, response, chunk) {
      this.$notify({
        title: '提示',
        message: '文件上传错误',
        type: 'warning',
        duration: 3000
      })
    },
    // ****************************************************************************************************************
    setTitle(title) {
      if (title.length > 50) {
        this.form.title = title.substring(0, 50)
        this.form.description = title
      } else {
        this.form.title = title
      }
    },
    onSubmit() {
      if (!this.form.audioFileId) {
        this.$notify({
          title: '提示',
          message: '你还没有上传音频',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      if (this.form.title === null) {
        this.$notify({
          title: '提示',
          message: '稿件标题不能为空',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      addAudioPost(this.form).then(res => {
        if (res.code === 0) {
          this.$notify({
            title: '提示',
            message: '投稿成功',
            type: 'warning',
            duration: 3000
          })
          this.$router.push('/post/list/audio')
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'warning',
          duration: 3000
        })
      })
    }
  }
}
</script>

<style>
.uploader-example {
  width: 500px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 240px;
  display: block;
}

.uploader-example {
  width: 500px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 240px;
  display: block;
}
</style>
